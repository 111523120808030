import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import PageLoader from "@/components/PageLoader";

const Dashboard = lazy(() => import("@/pages/Dashboard"));

// Dashboard Admin
const Admin = lazy(() => import("@/pages/Admin"));

// Singapore
const SingaporeDoctor = lazy(() => import("@/pages/Singapore/SingaporeDoctor"));
const SingaporeHospital = lazy(() => import("@/pages/Singapore/SingaporeHospital"));
const SingaporeClinic = lazy(() => import("@/pages/Singapore/SingaporeClinic"));
const SingaporePharmacy = lazy(() => import("@/pages/Singapore/SingaporePharmacy"));
const SingaporeDrug = lazy(() => import("@/pages/Singapore/SingaporeDrug"));

// Vietnam
const VietnamDoctor = lazy(() => import("@/pages/Vietnam/VietnamDoctor"));
const VietnamHospital = lazy(() => import("@/pages/Vietnam/VietnamHospital"));
const VietnamClinic = lazy(() => import("@/pages/Vietnam/VietnamClinic"));
const VietnamPharmacy = lazy(() => import("@/pages/Vietnam/VietnamPharmacy"));
const VietnamDistributor = lazy(() => import("@/pages/Vietnam/VietnamDistributor"));
const VietnamManufacturer = lazy(() => import("@/pages/Vietnam/VietnamManufacturer"));
const VietnamDrug = lazy(() => import("@/pages/Vietnam/VietnamDrug"));

// Malaysia
const MalaysiaDoctor = lazy(() => import("@/pages/Malaysia/MalaysiaDoctor"));
const MalaysiaHospital = lazy(() => import("@/pages/Malaysia/MalaysiaHospital"));
const MalaysiaClinic = lazy(() => import("@/pages/Malaysia/MalaysiaClinic"));
const MalaysiaPharmacy = lazy(() => import("@/pages/Malaysia/MalaysiaPharmacy"));
const MalaysiaDrug = lazy(() => import("@/pages/Malaysia/MalaysiaDrug"));

// Others
const Logout = lazy(() => import("@/pages/Logout"));
const NotFound = lazy(() => import("@/pages/NotFound"));

export default function AppRouter() {
  const location = useLocation();
  return (
    <Suspense fallback={<PageLoader />}>
      <AnimatePresence exitBeforeEnter initial={false}>
        <Switch location={location} key={location.pathname}>
          <PrivateRoute path="/" component={Dashboard} exact />

          <PrivateRoute component={SingaporeDoctor} path="/singapore_doctor" exact />
          <PrivateRoute component={SingaporeHospital} path="/singapore_hospital" exact />
          <PrivateRoute component={SingaporeClinic} path="/singapore_clinic" exact />
          <PrivateRoute component={SingaporePharmacy} path="/singapore_pharmacy" exact />
          <PrivateRoute component={SingaporeDrug} path="/singapore_drug" exact />

          <PrivateRoute component={VietnamDoctor} path="/vietnam_doctor" exact />
          <PrivateRoute component={VietnamHospital} path="/vietnam_hospital" exact />
          <PrivateRoute component={VietnamClinic} path="/vietnam_clinic" exact />
          <PrivateRoute component={VietnamPharmacy} path="/vietnam_pharmacy" exact />
          <PrivateRoute component={VietnamDistributor} path="/vietnam_distributor" exact />
          <PrivateRoute component={VietnamManufacturer} path="/vietnam_manufacturer" exact />
          <PrivateRoute component={VietnamDrug} path="/vietnam_drug" exact />

          <PrivateRoute component={MalaysiaDoctor} path="/malaysia_doctor" exact />
          <PrivateRoute component={MalaysiaHospital} path="/malaysia_hospital" exact />
          <PrivateRoute component={MalaysiaClinic} path="/malaysia_clinic" exact />
          <PrivateRoute component={MalaysiaPharmacy} path="/malaysia_pharmacy" exact />
          <PrivateRoute component={MalaysiaDrug} path="/malaysia_drug" exact />
          
          <PrivateRoute component={Admin} path="/admin" exact />

          <PrivateRoute component={Logout} path="/logout" exact />
          <PublicRoute path="/login" render={() => <Redirect to="/" />} />
          <Route
            path="*"
            component={NotFound}
            render={() => <Redirect to="/notfound" />}
          />
        </Switch>
      </AnimatePresence>
    </Suspense>
  );
}
