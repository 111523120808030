import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Image } from "antd";
import {
  SettingOutlined,
  MedicineBoxOutlined,
  DashboardOutlined,
  ShopOutlined,
  UsergroupAddOutlined
} from "@ant-design/icons";
import uniqueId from "@/utils/uinqueId";

const { Sider } = Layout;
const { SubMenu, Item } = Menu;

function Navigation() {
  const [collapsed, setCollapsed] = useState(false);

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };
  return (
    <>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        style={{
          zIndex: 1000,
        }}
      >
        <Image src="/logo500.png" />
        <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
          <Item key={`${uniqueId()}`} icon={<DashboardOutlined />}>
            <Link to="/">Dashboard</Link>
          </Item>

          <SubMenu key={`${uniqueId()}`} title="Singapore">
            <Item key={`${uniqueId()}`} icon={<UsergroupAddOutlined />}>
              <Link to="/singapore_doctor">Doctor</Link>
            </Item>
            <Item key={`${uniqueId()}`} icon={<ShopOutlined />}>
              <Link to="/singapore_hospital">Hospital</Link>
            </Item>
            <Item key={`${uniqueId()}`} icon={<ShopOutlined />}>
              <Link to="/singapore_clinic">Clinic</Link>
            </Item>
            <Item key={`${uniqueId()}`} icon={<ShopOutlined />}>
              <Link to="/singapore_pharmacy">Pharmacy</Link>
            </Item>
            <Item key={`${uniqueId()}`} icon={<MedicineBoxOutlined />}>
              <Link to="/singapore_drug">Drug</Link>
            </Item>
          </SubMenu>

          <SubMenu key={`${uniqueId()}`} title="Vietnam">
            <Item key={`${uniqueId()}`} icon={<UsergroupAddOutlined />}>
              <Link to="/vietnam_doctor">Doctor</Link>
            </Item>
            <Item key={`${uniqueId()}`} icon={<ShopOutlined />}>
              <Link to="/vietnam_hospital">Hospital</Link>
            </Item>
            <Item key={`${uniqueId()}`} icon={<ShopOutlined />}>
              <Link to="/vietnam_clinic">Clinic</Link>
            </Item>
            <Item key={`${uniqueId()}`} icon={<ShopOutlined />}>
              <Link to="/vietnam_pharmacy">Pharmacy</Link>
            </Item>
            <Item key={`${uniqueId()}`} icon={<ShopOutlined />}>
              <Link to="/vietnam_distributor">Distributor</Link>
            </Item>
            <Item key={`${uniqueId()}`} icon={<ShopOutlined />}>
              <Link to="/vietnam_manufacturer">Manufacturer</Link>
            </Item>
            <Item key={`${uniqueId()}`} icon={<MedicineBoxOutlined />}>
              <Link to="/vietnam_drug">Drug</Link>
            </Item>
          </SubMenu>

          <SubMenu key={`${uniqueId()}`} title="Malaysia">
            <Item key={`${uniqueId()}`} icon={<UsergroupAddOutlined />}>
              <Link to="/malaysia_doctor">Doctor</Link>
            </Item>
            <Item key={`${uniqueId()}`} icon={<ShopOutlined />}>
              <Link to="/malaysia_hospital">Hospital</Link>
            </Item>
            <Item key={`${uniqueId()}`} icon={<ShopOutlined />}>
              <Link to="/malaysia_clinic">Clinic</Link>
            </Item>
            <Item key={`${uniqueId()}`} icon={<ShopOutlined />}>
              <Link to="/malaysia_pharmacy">Pharmacy</Link>
            </Item>
            <Item key={`${uniqueId()}`} icon={<MedicineBoxOutlined />}>
              <Link to="/malaysia_drug">Drug</Link>
            </Item>
          </SubMenu>

          <Item key={`${uniqueId()}`} icon={<SettingOutlined />}>
            <Link to="/admin">Admin</Link>
          </Item>
        </Menu>
      </Sider>
    </>
  );
}
export default Navigation;
